/* CUSTOM CSS / OVERRIDES */

body {
}

.row {
    //background-color: rgba(255,0,0,0.5);
    //border-bottom: 1px dotted rgba(255,0,0,0.5);
}

/* Fonts:
 */

.edit_link {
	position: absolute;
	top: 0;
	right: 20px;
	padding: 0.3rem;
}

/* 
 *
 * TYPOGRAPHY
 *
 */

h1.smaller {
    font-size: rem-calc(40);
}

.nowrap {
    white-space: nowrap;
}

a.button {
    text-decoration: none;
}

.button:hover, .button.primary:hover {
    background-color: $secondary-color;
}

.button.outlined { 
    border-color: #000; 
    border-width: 3px; 
    color: #000; 
}

.button.large {
	font-weight: 700;
	padding: rem-calc(20 64);
}

/* Ensure P tags colour stays the default grey inside of links */
a p { color: #323241; }

p:empty {
   display: none;
}

.responsive-embed {
    box-shadow: 0 2px 20px rgba($black, 0.5);
}

h2, h3, h4, h5, h6, label, .reveal.full .menu a, .callout:before {
    font-family: 'Open Sans', serif;
}

h2, h3, h4, h5, h6, .reveal.full .menu a, .callout:before {
    font-weight: 700;
}

.small_list li {
	font-size: rem-calc(15);
}

/* 0 - 739px - Small Only */
@media only screen and ( max-width: 46.188em ) {
	html {
		font-size: rem-calc(16);
	}
}

/* HEADINGS */
.is-style-filled-heading-grape {
	background-color: #B05A9A !important;
	color: #fff !important;
	padding: 1.25rem 1rem !important;
}

.is-style-filled-heading-plum {
	background-color: #6A5489 !important;
	color: #fff !important;
	padding: 1.25rem 1rem !important;
}

.is-style-filled-heading-green {
	background-color: #00ADAC !important;
	color: #fff !important;
	padding: 1.25rem 1rem !important;
}
.is-style-filled-heading-blue {
	background-color: #00658B !important;
	color: #fff !important;
	padding: 1.25rem 1rem !important;
}

/* LISTS */
.is-style-documents {
	list-style: none;
	margin-top: rem-calc(30);
	margin-left: 0;
}

.is-style-documents li {
	background-image: documentIcon(#323241);
    background-position: top 10px left;
    background-repeat: no-repeat;
    background-size: 20px;
	padding-left: rem-calc(40);
	padding-top: rem-calc(10);
	padding-bottom: rem-calc(10);
}

.is-style-documents li strong {
	font-weight: 700;
}

.is-style-arrows {
	list-style: none;
	margin-top: rem-calc(30);
	margin-left: 0;
}

.is-style-arrows li {
	background-image: svgRightArrow(#00B9B9);
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 20px;
	padding-left: rem-calc(36);
	padding-top: rem-calc(10);
	padding-bottom: rem-calc(10);
}

.is-style-arrow-square {
	list-style: none;
	margin-top: rem-calc(30);
	margin-left: 0;
}

.is-style-arrow-square li {
	background-image: arrowSquareIcon(#323241);
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 30px;
	padding-left: rem-calc(42);
	padding-top: rem-calc(10);
	padding-bottom: rem-calc(10);
}


/* QR ICON SHORTCODE STYLES */
.qr_icon {
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	text-align: center;
	text-indent: -5000px;
}
.qr_icon_yes {
	background-image: tickIcon(#555462);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 24px;
}
.qr_icon_no {
	background-image: xIcon(#555462);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 24px;
}

/*
 * TAB STYLES
 */

.tabs, .accordion-content ul.tabs {
	margin-bottom: 0;
	display: flex;
	flex-direction: row;
}
.tabs-title {
	max-width: 31%;
	margin-right: 2%;
	opacity: 0.5;
	transition: opacity 0.25s;
	flex-grow: 1;
	float: none;
}

.tabs-title:last-child() {
	margin-right: 0;
}

.tabs-title > a strong {
	padding: rem-calc(10 0 10 0);
	color: #000;
	display: block;
}

.tabs-title > a, .tabs-title > a[aria-selected='true'] {
	text-decoration: none;
	border-bottom: 5px solid rgba(0,0,0,0.4);
	font-weight: 700;
	color: #000;
	transition: all 0.35s;
}

.tabs-title.is-active, .tabs-title:hover, .accordion-content .tabs-title a:hover {
	opacity: 1;
	color: #000;
}

.tabs-title.is-active > a, .tabs-title > a:hover {
	border-bottom: 5px solid $secondary-color;
	text-decoration: none;
}


.tabs.main_twin_tabs:after, .tabs.main_twin_tabs:before {
	display: none;
	clear: none;
}

.tabs.main_twin_tabs .tabs-title {
	max-width: 50%;
	margin-right: 10px;
	opacity: 1;
}

.tabs.main_twin_tabs .tabs-title:last-child() {
	margin-right: 0;
}

.tabs.main_twin_tabs .tabs-title:last-child() a {
	padding-left: rem-calc(2);
}

.tabs.main_twin_tabs .tabs-title > a {
	text-decoration: none;
	font-weight: 400;
	color: #000;
	border-bottom: 11px solid transparent;
	padding-bottom: rem-calc(20);
}
.tabs.main_twin_tabs .tabs-title > a[aria-selected='true'],
.tabs.main_twin_tabs tabs-title.is-active > a, 
.tabs.main_twin_tabs .tabs-title > a:hover {
	border-bottom: 11px solid $secondary-color;
	text-decoration: none;
	font-weight: 700;
}

.tabs.main_twin_tabs.purple .tabs-title:nth-child(1) > a[aria-selected='true'],
.tabs.main_twin_tabs.purple .tabs-title:nth-child(1).is-active > a, 
.tabs.main_twin_tabs.purple .tabs-title:nth-child(1) > a:hover {
	border-bottom: 11px solid #B05A9A;
}
.tabs.main_twin_tabs.purple .tabs-title:nth-child(2) > a[aria-selected='true'],
.tabs.main_twin_tabs.purple .tabs-title:nth-child(2).is-active > a, 
.tabs.main_twin_tabs.purple .tabs-title:nth-child(2) > a:hover {
	border-bottom: 11px solid #6A5489;
}

.tabs.main_twin_tabs.orange .tabs-title:nth-child(1) > a[aria-selected='true'],
.tabs.main_twin_tabs.orange .tabs-title:nth-child(1).is-active > a, 
.tabs.main_twin_tabs.orange .tabs-title:nth-child(1) > a:hover {
	border-bottom: 11px solid #D77B41;
}
.tabs.main_twin_tabs.orange .tabs-title:nth-child(2) > a[aria-selected='true'],
.tabs.main_twin_tabs.orange .tabs-title:nth-child(2).is-active > a, 
.tabs.main_twin_tabs.orange .tabs-title:nth-child(2) > a:hover {
	border-bottom: 11px solid #D77B41;
}

.tabs-content.main_twin_tabs {
	margin-bottom: rem-calc(30);
}

.tabs-content.main_twin_tabs .tabs-panel {
	padding: rem-calc(35 30);
}

.tabs-content.main_twin_tabs .tabs-panel .wp-block-media-text__content p {
	font-size: rem-calc(24);
}

.tabs-content.main_twin_tabs .tabs-panel .wp-block-media-text__media {
	padding-top: 0;
}

.tabs-content.main_twin_tabs .tabs-panel.light-grape {
	background-color: #F3E6F0;
}

.tabs-content.main_twin_tabs .tabs-panel.light-plum {
	background-color: #E9E5ED;
}

.tabs-content.main_twin_tabs .tabs-panel.light-orange {
	background-color: #F9EBE2;
}

.tabs-content.main_twin_tabs .tabs-panel .wp-block-quote {
	background-color: #fff;
	margin: 0;
	padding: rem-calc(20 20);
	border: none;
	border-left: none;
}

.tabs-content.main_twin_tabs .tabs-panel .wp-block-quote p {
	color: #000;
	padding: rem-calc(30 30 35 30);
	margin: 0;
}
.tabs-content.main_twin_tabs .tabs-panel.light-grape .wp-block-quote p {
	background-color: #B05A9A;
	color: #fff;
	
}

.tabs-content.main_twin_tabs .tabs-panel.light-plum .wp-block-quote p {
	background-color: #6A5489;
	color: #fff;
}


/* CALLOUT QUOTE BLOCK */
.wp-block-quote.is-style-callout-quote {
	background-color: #fff;
	margin: 0;
	padding: rem-calc(20 20);
	border: none;
	border-left: none;
}
.wp-block-quote.is-style-callout-quote p {
	color: #000;
	padding: rem-calc(30 30 35 30);
	margin: 0;
}
.wp-block-quote.is-style-callout-quote.unpad-content p {
	padding: 0;
}
.wp-block-quote.is-style-callout-quote.light-grape p {
	background-color: #B05A9A;
	color: #fff;
	
}
.wp-block-quote.is-style-callout-quote.light-plum p {
	background-color: #6A5489;
	color: #fff;
}
.wp-block-quote.is-style-callout-quote.blue p {
	background-color: #00658B;
	color: #fff;
}
.wp-block-quote.is-style-callout-quote.light-blue p {
	background-color: #EEF4F7;
	color: #000;
}

.wp-block-media-text.callout_block {
	background-color: #fff;
	margin: 0;
	padding: rem-calc(20 20);
	border: none;
	border-left: none;
}

.wp-block-media-text.callout_block.light-grape {
	background-color: #B05A9A;
}
.wp-block-media-text.callout_block.light-grape p {
	color: #fff;
}
.wp-block-media-text.callout_block.light-plum {
	background-color: #6A5489;
}
.wp-block-media-text.callout_block.light-plum p {
	color: #fff;
}
.wp-block-media-text.callout_block.blue {
	background-color: #00658B;
}
.wp-block-media-text.callout_block.blue p {
	color: #fff;
}

.wp-block-media-text.callout_block .wp-block-media-text__content p {
	font-size: rem-calc(24);
}

.wp-block-media-text.callout_block .wp-block-media-text__media {
	//padding-top: 0;
}


/*
 * COOKIE CONSENT BAR
 */
#cookie-law-info-bar { padding: rem-calc(60); }
#cookie-law-info-bar p a { border: 1px solid #00B9B9; font-weight: bold; text-decoration: none; padding: rem-calc(15 25); font-size: rem-calc(16); }
#cookie-law-info-bar p a:hover { border: 1px solid #00ADAC !important; background-color: #00ADAC !important; color: #fff !important; }


/*
 * DATE / SHARING BAR
 */
.date-sharing {
	color: $primary-color;
}

.date-sharing #st-1 .st-btn, 
.date-sharing #st-1 .st-btn[data-network='facebook'], 
.date-sharing #st-1 .st-btn[data-network='twitter'], 
.date-sharing #st-1 .st-btn[data-network='email'] {
	border: 1px solid $primary-color;
	background-color: transparent !important;
	border-radius: 999px;
	width: 35px;
	height: 35px;
}

.date-sharing #st-1 .st-btn img {
	display: none !important;
}

.date-sharing #st-1 .st-btn[data-network='facebook'] {
	background-image: facebookIcon( $primary-color );
	background-position: center;
    background-repeat: no-repeat;
    background-size: 18px 18px;
}

.date-sharing #st-1 .st-btn[data-network='twitter'] {
	background-image: twitterIcon( $primary-color );
	background-position: center;
    background-repeat: no-repeat;
    background-size: 16px 16px;
}

.date-sharing #st-1 .st-btn[data-network='email'] {
	background-image: emailIcon( $primary-color );
	background-position: center;
    background-repeat: no-repeat;
    background-size: 16px 16px;
}


/*
 * CONTACT FORM 7 STYLES / GENERIC FORMS STYLES
 */
label span input {
	margin-top: rem-calc(10) !important;
}

.wpcf7 p {
	margin-bottom: 0.5rem;
}

.wpcf7 .wpcf7-text, .wpcf7 .wpcf7-textarea {
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
}

.wpcf7 td .wpcf7-text, .wpcf7 td .wpcf7-textarea {
	margin-top: 0;
	margin-bottom: 0;
}

span.wpcf7-list-item {
	margin-left: 0;
}
.wpcf7 [type='checkbox'], .wpcf7 [type='radio'] {
	width: rem-calc(44);
	height: rem-calc(44);
	//opacity: 0;
	border: 1px solid #B9BABB;
	appearance: none;
	margin: rem-calc(5 20 20 0);
} 
.wpcf7 [type='checkbox'] {
	float: left;
} 
.wpcf7 [type='checkbox']:hover, .wpcf7 [type='radio']:hover {
	cursor: pointer;
}
.wpcf7 [type='checkbox']:checked, .wpcf7 [type='radio']:checked {
	cursor: pointer;
	background-image: checkMark(#000);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 24px;
}

.wpcf7-list-item-label {
	font-size: rem-calc(18);
	line-height: 1.4;
}

.wpcf7 h3, .content_container .wpcf7 h3 {
	font-size: rem-calc(18);
	padding-top: rem-calc(30);
}

.wpcf7 span.wpcf7-not-valid-tip { margin-top:-1rem; margin-bottom:0.5rem; padding:rem-calc(5 8); background-color:#ffdf72; color:#333; line-height:1; border-radius:0; font-size: rem-calc(14); }
.wpcf7 div.wpcf7-validation-errors { background:#ffdf72; border:none; margin-left:0; margin-right:0; margin-bottom:1.3rem; padding:rem-calc(16); font-weight:bold; border-radius:0; }
.wpcf7 div.wpcf7-mail-sent-ok { background:#00ADAC; border:none; margin-left:0; margin-right:0; margin-bottom:1rem; padding:rem-calc(16); font-weight:bold; }
.wpcf7 div.wpcf7-mail-sent-ng { background:#c14114; border:none; margin-left:0; margin-right:0; margin-bottom:1rem; padding:rem-calc(16); font-weight:bold; color:#fff; }
.wpcf7 div.wpcf7-spam-blocked { background:#ffc704; border:none; margin-left:0; margin-right:0; margin-bottom:1rem; padding:rem-calc(16); font-weight:bold; }
.wpcf7-recaptcha { margin-bottom:1rem; }


.wpcf7 select {
	background-image: svgCaretArrow(#000,down);
    background-position: right -5px center;
    background-repeat: no-repeat;
    background-size: 16px;
}

.wpcf7-radio.horz-layout-large {
	display: flex;
	flex-direction: row;
}

.wpcf7-radio.horz-layout-large .wpcf7-list-item {
	margin-right: rem-calc(60);
}

.wpcf7-radio.horz-layout-large .wpcf7-list-item label {
	display: flex;
	flex-direction: row;
}

.wpcf7-radio.horz-layout-large .wpcf7-list-item input[type="radio"] {
	margin-right: rem-calc(20);
}

.wpcf7-radio.horz-layout-large .wpcf7-list-item .wpcf7-list-item-label {
	margin-top: rem-calc(5);
	height: rem-calc(44);
	line-height: rem-calc(44);
}

.date-picker {
	background-image: url(../img/calendar-icon.svg);
    background-position: right 20px center;
    background-repeat: no-repeat;
    background-size: 24px;
	padding-right: rem-calc(54);
}

.orbit-wrapper.shortcode-slider {
	padding: 2px;
}


/*h2 strong, h2 b {
    font-weight: 400;
    color: #4C506D;
}*/

h2.primary, h3.primary, p.primary, span.primary, strong.primary, em.primary, th.primary, td.primary {
    color: $primary-color;
}

h2.plum, h3.plum, h4.plum, h5.plum, h6.plum, p.plum, span.plum, strong.plum, em.plum, th.plum, td.plum {
    color: #6A5489;
}

h2.is-style-plum, h3.is-style-plum, h4.is-style-plum, h5.is-style-plum, h6.is-style-plum, p.is-style-plum, span.is-style-plum, strong.is-style-plum, em.is-style-plum, th.is-style-plum, td.is-style-plum {
    color: #6A5489;
}

.wp-block-image {
	margin-top: 1rem;
}



/* 
 * CORE COLUMN BLOCK
 */
.wp-block-columns.no_mobile_wrap {
	flex-wrap: nowrap !important;
}

/* 0 - 739px - Small Only */
@media only screen and ( max-width: 46.188em ) {
 	.wp-block-columns.no_mobile_wrap h4 {
		font-size: rem-calc(14);
	}
}



/* 
 * GENERIC TABLE STYLES
 */
table.primary {
	background-color: $primary-light;
}

table.two-col-50 th, table.two-col-50 td {
	width: 50%;
}

table.key_form {
	background-color: $primary-light;
}

table.key_form td {
	vertical-align: top;
}

table.key_form p {
	margin-bottom: rem-calc(5);
}

table.key_form h4 {
	margin-top: rem-calc(10);
	margin-bottom: rem-calc(5);
}

/* CLEAN TABLE WITH TOP HEADER ROW, NO VERTICAL BORDERS, DASHED ROW BORDERS */
.clean-table thead {
	border-bottom: 1px dashed #BEBEBE;
}
.clean-table tbody {
	border-top: 1px dashed #BEBEBE;
	border-bottom: 1px dashed #BEBEBE;
}
.clean-table tbody tr {
	border-top: 1px dashed #BEBEBE;
	background-color: transparent;
}
.clean-table tbody tr th {
	font-weight: 700;
	color: $primary-color;
	background-color: transparent;
	vertical-align: middle;
}
.clean-table tbody tr:nth-child(even) {
	background-color: transparent;
}
.clean-table tbody tr td {
	border-top: none;
	border-left: none;
	border-bottom: none;
	border-right: none;
	vertical-align: middle;
}

.wpcf7-form-control-wrap {
	overflow: hidden;
	display: block;
	position: relative;
}

th.align-top, td.align-top {
	vertical-align: top !important;
}

/* 
 * TABLE WITH INPUT FIELD ICONS
 */
/*
.table-field-icons {}
*/

td.percent-icon .wpcf7-form-control-wrap:after {
	position: absolute;
	top: 1px;
	bottom: 1px;
	right: 1px;
	content: '%';
	background-color: #ccc;
	line-height: 1;
	padding: 0.88889rem 0.4rem;
}

td.percent-100-icon .wpcf7-form-control-wrap:after {
	position: absolute;
	top: 1px;
	bottom: 1px;
	right: 1px;
	content: '100%';
	background-color: #ccc;
	line-height: 1;
	padding: 0.88889rem 0.4rem;
}


table.width_70_30 {
	table-layout: fixed;
	width: 100%;
}
table.width_70_30 tr th:nth-child(1), table.width_70_30 tr td:nth-child(1) {
	width: 70%;
}
table.width_70_30 tr th:nth-child(2), table.width_70_30 tr td:nth-child(2) {
	width: 30%;
}

input.warning {
	border-color: red;
	color: red;
}


/* 
 * CORE TABLE BLOCK
 */
/* DEFAULT TABLE IS 2 COLUMNS WITH WHITE BACKGROUND */
.wp-block-table {}
.wp-block-table td {
	width: 50%;
	vertical-align: top;
}
/* 2 COLUMN WITH 66 / 33 SPLIT */
.wp-block-table.two_66_33 tr td:nth-child(1) {
	width: 66.666%;
}
.wp-block-table.two_66_33 tr td:nth-child(2) {
	width: 33.333%;
}
/* 4 COLUMN WITH 25 SPLIT */
.wp-block-table.four_25 tr td {
	width: 25%;
}
.wp-block-table thead, .wp-block-table tbody, .wp-block-table tfoot {
	border: none;
}
.wp-block-table tbody tr {
	background-color: #fff;
}
.wp-block-table tbody tr:nth-child(even) {
	background-color: #fff;
}
.wp-block-table tbody tr td:nth-child(1) {
	border-right: 1px solid #BEBEBE;
}
.wp-block-table tbody tr td:nth-child(2) {
	padding-left: 1rem;
}
.wp-block-table tbody tr {
	border-bottom: 1px solid #BEBEBE;
}

.wp-block-table tbody tr:last-child {
	border-bottom: none;
}

.wp-block-table.no-row-border tbody tr {
	border-bottom: none;
}

.wp-block-table img {
	width: 100% !important;
}

/* 2 COLUMN TABLE WITH TOP HEADER ROW, NO VERTICAL BORDERS, DASHED ROW BORDERS */
.wp-block-table.top-header tbody tr:nth-child(1) td {
	border-bottom: 1px solid #000;
	font-weight: 700;
}
.wp-block-table.no-background tbody tr {
	background-color: transparent;
}
.wp-block-table.no-background tbody tr:nth-child(even) {
	background-color: transparent;
}
.wp-block-table.no-vertical-border tbody tr td:nth-child(1) {
	border-right: none;
}
.wp-block-table.dashed-row-borders tbody tr {
	border-bottom: 1px dashed #BEBEBE;
}

.wp-block-columns.bottom-margin-zero .wp-block-column {
	margin-bottom: 0;
}

/* No Styling Format */
.wp-block-table.is-style-no-styling {
}
.wp-block-table.is-style-no-styling tbody tr {
	background-color: transparent;
}
.wp-block-table.is-style-no-styling tbody tr:nth-child(even) {
	background-color: transparent;
}
.wp-block-table.is-style-no-styling tbody tr td:nth-child(1) {
	border-right: none;
}
.wp-block-table.is-style-no-styling tbody tr {
	border-bottom: none;
}
.wp-block-table.is-style-no-styling tr td {
	padding-left: 0;
}

/* Dotted Rows */
.wp-block-table.is-style-dotted-rows {
}
.wp-block-table.is-style-dotted-rows tbody tr {
	background-color: transparent;
}
.wp-block-table.is-style-dotted-rows tbody tr:nth-child(even) {
	background-color: transparent;
}
.wp-block-table.is-style-dotted-rows tbody tr td:nth-child(1) {
	border-right: none;
}
.wp-block-table.is-style-dotted-rows tbody tr {
	border-bottom: 1px dashed #BEBEBE;
}
.wp-block-table.is-style-dotted-rows tr td {
	padding-left: 0;
}


/* 4 Col w/ Header & Striped Rows */
.wp-block-table.is-style-four-col-top-header-striped-rows {}
.wp-block-table.is-style-four-col-top-header-striped-rows tbody tr:nth-child(1) td {
	border-bottom: 1px solid #000;
	font-weight: 700;
}
.wp-block-table.is-style-four-col-top-header-striped-rows tr td {
	width: 25%;
}
.wp-block-table.is-style-four-col-top-header-striped-rows tbody tr {
	background-color: transparent;
}
.wp-block-table.is-style-four-col-top-header-striped-rows tbody tr:nth-child(even) {
	background-color: transparent;
}
.wp-block-table.is-style-four-col-top-header-striped-rows tbody tr td:nth-child(1) {
	border-right: none;
}
.wp-block-table.is-style-four-col-top-header-striped-rows tbody tr {
	border-bottom: 1px dashed #BEBEBE;
}
.wp-block-table.is-style-four-col-top-header-striped-rows tr td {
	padding-left: 0;
}

/* GA TABLE OVERRIDE FOR 4 COL */
.wp-block-table.is-style-four-col-top-header-striped-rows.ga_table tr td {
	width: 20%;
}
.wp-block-table.is-style-four-col-top-header-striped-rows.ga_table tr td:nth-child(3) {
	width: 40%;
}

/* 2 Col 66/33 Split w/ Header & Striped Rows */
.wp-block-table.is-style-two-col-66-33-split-top-header-striped-rows {}
.wp-block-table.is-style-two-col-66-33-split-top-header-striped-rows tbody tr:nth-child(1) td {
	border-bottom: 1px solid #000;
	font-weight: 700;
}
.wp-block-table.is-style-two-col-66-33-split-top-header-striped-rows tr td:nth-child(1) {
	width: 66.666%;
}
.wp-block-table.is-style-two-col-66-33-split-top-header-striped-rows tr td:nth-child(2) {
	width: 33.333%;
}
.wp-block-table.is-style-two-col-66-33-split-top-header-striped-rows tbody tr {
	background-color: transparent;
}
.wp-block-table.is-style-two-col-66-33-split-top-header-striped-rows tbody tr:nth-child(even) {
	background-color: transparent;
}
.wp-block-table.is-style-two-col-66-33-split-top-header-striped-rows tbody tr td:nth-child(1) {
	border-right: none;
}
.wp-block-table.is-style-two-col-66-33-split-top-header-striped-rows tbody tr {
	border-bottom: 1px dashed #BEBEBE;
}
.wp-block-table.is-style-two-col-66-33-split-top-header-striped-rows tr td {
	padding-left: 0;
}

/* 3 Col 26/37/37 Split - PLAIN */
.wp-block-table.is-style-three-col-26-37-37-split tr {
	border-bottom: 3px solid #fff;
}
.wp-block-table.is-style-three-col-26-37-37-split tr td {
	padding-top: 0.6rem;
	padding-bottom: 0.6rem;
}
.wp-block-table.is-style-three-col-26-37-37-split tr td:nth-child(1) {
	width: 26%;
	border: none;
	border-right: 7px solid #fff;
	font-weight: bold;
}
.wp-block-table.is-style-three-col-26-37-37-split tr td:nth-child(2) {
	width: 37%;
	border: none;
	border-left: 7px solid #fff;
	border-right: 7px solid #fff;
}
.wp-block-table.is-style-three-col-26-37-37-split tr td:nth-child(3) {
	width: 37%;
	border: none;
	border-left: 7px solid #fff;
}

/* 3 Col 26/37/37 Split - PURPLE 2 & 3 */
.wp-block-table.is-style-three-col-26-37-37-split-purple-2-3 tr td {
	padding-top: 1rem;
	padding-bottom: 1rem;
}
.wp-block-table.is-style-three-col-26-37-37-split-purple-2-3 tr td:nth-child(1) {
	width: 26% !important;
	border: none;
	border-right: 7px solid #fff;
	background-color: transparent;
}
.wp-block-table.is-style-three-col-26-37-37-split-purple-2-3 tr td:nth-child(2) {
	width: 37% !important;
	border: none;
	border-left: 7px solid #fff;
	border-right: 7px solid #fff;
	background-color: #B05A9A;
	font-weight: bold;
	color: #fff;
	padding-left: 0.44444rem;
}
.wp-block-table.is-style-three-col-26-37-37-split-purple-2-3 tr td:nth-child(3) {
	width: 37% !important;
	border: none;
	border-left: 7px solid #fff;
	background-color: #6A5489;
	font-weight: bold;
	color: #fff;
}

/* 3 Col 26/37/37 Split - LIGHT PURPLE COLOURS */
.wp-block-table.is-style-three-col-26-37-37-split-light-purple tr {
	border-bottom: 3px solid #fff;
}
.wp-block-table.is-style-three-col-26-37-37-split-light-purple tr td {
	padding-top: 0.6rem;
	padding-bottom: 0.6rem;
}
.wp-block-table.is-style-three-col-26-37-37-split-light-purple tr td:nth-child(1) {
	width: 26%;
	border: none;
	border-right: 7px solid #fff;
	font-weight: bold;
	background-color: #F0F2F4;
}
.wp-block-table.is-style-three-col-26-37-37-split-light-purple tr td:nth-child(2) {
	width: 37%;
	border: none;
	border-left: 7px solid #fff;
	border-right: 7px solid #fff;
	background-color: #EBD3E6;
	padding-left: 0.44444rem;
}

.wp-block-table.is-style-three-col-26-37-37-split-light-purple tr td:nth-child(3) {
	width: 37%;
	border: none;
	border-left: 7px solid #fff;
	background-color: #D9D3E3;
}

/* 3 Col 33/33/33 Split White BG */
.wp-block-table.is-style-three-col-33-33-33-split-white-bg-right-col-purple-text {
	margin-bottom: 3px;
}
.wp-block-table.is-style-three-col-33-33-33-split-white-bg-right-col-purple-text tr {
	border-bottom: 3px solid transparent;
}
.wp-block-table.is-style-three-col-33-33-33-split-white-bg-right-col-purple-text tbody tr {
	background-color: #fff !important;
}
.wp-block-table.is-style-three-col-33-33-33-split-white-bg-right-col-purple-text tbody tr:nth-child(even) {
	background-color: #fff !important;
}
.wp-block-table.is-style-three-col-33-33-33-split-white-bg-right-col-purple-text tr td {
	padding-top: 1rem;
	padding-bottom: 1rem;
	border: none !important;
	border-right: none !important;
	text-align: center;
	/*font-size: 18px !important;*/
}
.wp-block-table.is-style-three-col-33-33-33-split-white-bg-right-col-purple-text tr td strong {
	font-weight: bold !important;
	font-size: rem-calc(30);
}
.wp-block-table.is-style-three-col-33-33-33-split-white-bg-right-col-purple-text tr td:nth-child(1) {
	width: 33.333% !important;
	border-right: none !important;
}
.wp-block-table.is-style-three-col-33-33-33-split-white-bg-right-col-purple-text tr td:nth-child(2) {
	width: 33.333% !important;
}
.wp-block-table.is-style-three-col-33-33-33-split-white-bg-right-col-purple-text tr td:nth-child(3) {
	width: 33.333% !important;
	color: #B05A9A;
}
.wp-block-table.is-style-three-col-33-33-33-split-white-bg-right-col-purple-text.plum_text tr td:nth-child(3) {
	color: #6A5489 !important;
}
.wp-block-table.is-style-three-col-33-33-33-split-white-bg-right-col-purple-text.green_text tr td:nth-child(3) {
	color: #00ADAC !important;
}

/* 2 Col with Calendar Icon Headers */
.wp-block-table.is-style-two-col-50-calendar-icons {
	
}
.wp-block-table.is-style-two-col-50-calendar-icons tbody tr {
	border-bottom: none;
}
.wp-block-table.is-style-two-col-50-calendar-icons tr td {
	width: 50% !important;
	border-right: none !important;
	text-align: center;
	padding-top: rem-calc(15);
	padding-bottom: rem-calc(15);
}
.wp-block-table.is-style-two-col-50-calendar-icons tbody tr:first-child {
	background-color: #000;
}
.tabs-panel.light-grape .wp-block-table.is-style-two-col-50-calendar-icons tbody tr:first-child {
	background-color: #B05A9A;
}
.tabs-panel.light-plum .wp-block-table.is-style-two-col-50-calendar-icons tbody tr:first-child {
	background-color: #6A5489;
}
.wp-block-table.is-style-two-col-50-calendar-icons tbody tr:first-child td strong {
	display: inline-block;
	background-image: calendarIcon(#fff);
    background-position: top left ;
    background-repeat: no-repeat;
    background-size: 48px;
	color: #fff;
	padding-left: rem-calc(55);
	line-height: rem-calc(55);
}

.wp-block-table.is-style-two-col-50-calendar-icons tbody tr:nth-child(n+2) td strong {
	font-weight: bold !important;
	font-size: rem-calc(30);
}
.wp-block-table.is-style-two-col-50-calendar-icons tbody tr:nth-child(4) td {
	position: relative;
}

.wp-block-table.is-style-two-col-50-calendar-icons tbody tr:nth-child(4) td:after {
	content:'';
	position: absolute;
	top: 0;
	width: calc(100% - 40px);
	border-top: 3px solid #ECEDEF;
}

.wp-block-table.is-style-two-col-50-calendar-icons tbody tr:nth-child(4) td:first-child:after {
	right: 10px;
}

.wp-block-table.is-style-two-col-50-calendar-icons tbody tr:nth-child(4) td:last-child:after {
	left: 10px;
}

.wp-block-table.is-style-two-col-50-calendar-icons tbody tr:last-child td {
	font-weight: 600;
	position: relative;
}

.wp-block-table.is-style-two-col-50-calendar-icons tbody tr:last-child td:after {
	content:'';
	position: absolute;
	top: 0;
	width: calc(100% - 40px);
	border-top: 3px solid #707070;
}

.wp-block-table.is-style-two-col-50-calendar-icons tbody tr:last-child td:first-child:after {
	right: 10px;
}

.wp-block-table.is-style-two-col-50-calendar-icons tbody tr:last-child td:last-child:after {
	left: 10px;
}

.tabs-panel.light-grape .wp-block-table.is-style-two-col-50-calendar-icons tbody tr:last-child td {
	color: #B05A9A;
}

.tabs-panel.light-plum .wp-block-table.is-style-two-col-50-calendar-icons tbody tr:last-child td {
	color: #6A5489;
}


/* 2 Col with Narrow Left Column Headers */
.wp-block-table.is-style-two-col-narrow-left-header {}
.wp-block-table.is-style-two-col-narrow-left-header tbody tr:nth-child(1) td {
	border-bottom: none;
}
.wp-block-table.is-style-two-col-narrow-left-header.green_text tr td:nth-child(1) {
	color: #00ADAC;
}
.wp-block-table.is-style-two-col-narrow-left-header tr td:nth-child(1) {
	width: 12%;
	font-weight: 700;
}
.wp-block-table.is-style-two-col-narrow-left-header tr td:nth-child(2) {
	width: 88%;
}
.wp-block-table.is-style-two-col-narrow-left-header tbody tr {
	background-color: transparent;
}
.wp-block-table.is-style-two-col-narrow-left-header tbody tr:nth-child(even) {
	background-color: transparent;
}
.wp-block-table.is-style-two-col-narrow-left-header tbody tr td:nth-child(1) {
	border-right: none;
}
.wp-block-table.is-style-two-col-narrow-left-header tbody tr {
	border-bottom: none;
}
.wp-block-table.is-style-two-col-narrow-left-header tr td {
	padding-left: 0;
	vertical-align: middle;
}


/* 3 Col with Top & Right Column Headers */
.wp-block-table.is-style-three-col-38-31-31-top-right-header {}
.wp-block-table.is-style-three-col-38-31-31-top-right-header tbody tr {
	border-bottom: none;
}
.wp-block-table.is-style-three-col-38-31-31-top-right-header tbody tr:first-child {
	font-weight: 700;
}
.wp-block-table.is-style-three-col-38-31-31-top-right-header tbody tr:first-child td {
	font-size: rem-calc(21);
}
.wp-block-table.is-style-three-col-38-31-31-top-right-header tbody tr td:first-child {
	border-right: none;
	padding-left: rem-calc(30);
}
.wp-block-table.is-style-three-col-38-31-31-top-right-header tbody tr td:last-child {
	font-weight: 700;
	//font-size: rem-calc(21);
}
.wp-block-table.is-style-three-col-38-31-31-top-right-header tr td {
	text-align: center;
	padding-top: 20px;
	padding-bottom: 20px;
	vertical-align: middle;
}
.wp-block-table.is-style-three-col-38-31-31-top-right-header tr td:nth-child(1) {
	text-align: left;
	width: 38%;
}
.wp-block-table.is-style-three-col-38-31-31-top-right-header tr td:nth-child(2) {
	width: 31%;
	font-size: rem-calc(30);
}
.wp-block-table.is-style-three-col-38-31-31-top-right-header tr td:nth-child(3) {
	width: 31%;
	font-size: rem-calc(30);
}
.wp-block-table.is-style-three-col-38-31-31-top-right-header tbody tr td {
	position: relative;
}
.wp-block-table.is-style-three-col-38-31-31-top-right-header tbody tr:nth-child(n+2) td:after {
	content:'';
	position: absolute;
	top: 0;
	left: 20px;
	width: calc(100% - 40px);
	border-top: 3px solid #ECEDEF;
}
.wp-block-table.is-style-three-col-38-31-31-top-right-header tbody tr:last-child td:nth-child(n+2):after {
	border-color: #707070;
}
.wp-block-table.is-style-three-col-38-31-31-top-right-header.blue tbody tr:first-child td,
.wp-block-table.is-style-three-col-38-31-31-top-right-header.blue tbody tr:last-child td:last-child
{
	color: #00658B;
}
.wp-block-table.is-style-three-col-38-31-31-top-right-header.grape tbody tr:first-child td,
.wp-block-table.is-style-three-col-38-31-31-top-right-header.grape tbody tr:last-child td:last-child {
	color: #B05A9A;
}
.wp-block-table.is-style-three-col-38-31-31-top-right-header.plum tbody tr:first-child td,
.wp-block-table.is-style-three-col-38-31-31-top-right-header.plum tbody tr:last-child td:last-child {
	color: #6A5489;
}


/* 2 Col with Right Column Headers */
.wp-block-table.is-style-two-col-65-35-right-header {}
.wp-block-table.is-style-two-col-65-35-right-header tbody tr {
	border-bottom: none;
}
.wp-block-table.is-style-two-col-65-35-right-header tbody tr td:first-child {
	border-right: none;
	padding-left: rem-calc(30);
}
.wp-block-table.is-style-two-col-65-35-right-header tbody tr td:last-child strong {
	font-weight: 700;
}
.wp-block-table.is-style-two-col-65-35-right-header tr td {
	text-align: center;
	padding-top: 20px;
	padding-bottom: 20px;
	position: relative;
	vertical-align: middle;
}
.wp-block-table.is-style-two-col-65-35-right-header tr td:nth-child(1) {
	text-align: left;
	width: 65%;
}
.wp-block-table.is-style-two-col-65-35-right-header tr td:nth-child(2) {
	width: 35%;
	line-height: 1.1;
}
.wp-block-table.is-style-two-col-65-35-right-header tr td:nth-child(2) strong {
	font-size: rem-calc(30);
}
.wp-block-table.is-style-two-col-65-35-right-header tbody tr td:after {
	content:'';
	position: absolute;
	top: 0;
	width: calc(100% - 30px);
	border-top: 3px solid #ECEDEF;
}
.wp-block-table.is-style-two-col-65-35-right-header tbody tr td:first-child:after {
	left: 20px;
}
.wp-block-table.is-style-two-col-65-35-right-header tbody tr td:last-child:after {
	right: 20px;
}
.wp-block-table.is-style-two-col-65-35-right-header tbody tr:last-child td:after {
	border-color: #707070;
}
.wp-block-table.is-style-two-col-65-35-right-header.no-top-borders tbody tr td:after {
	border-color: transparent;
}
.wp-block-table.is-style-two-col-65-35-right-header.blue tbody tr:last-child td:last-child {
	color: #00658B;
}
.wp-block-table.is-style-two-col-65-35-right-header.blue-top-right-strong tbody tr:first-child td:last-child strong {
	color: #00658B;
}
.wp-block-table.is-style-two-col-65-35-right-header.grape tbody tr:last-child td:last-child {
	color: #B05A9A;
}
.wp-block-table.is-style-two-col-65-35-right-header.plum tbody tr:last-child td:last-child {
	color: #6A5489;
}


/* 3 Col 33/33/33 Green Alt Top Header */
.wp-block-table.is-style-three-col-33-33-33-green-alt-top-header {
	margin-bottom: 3px !important;
}
.wp-block-table.is-style-three-col-33-33-33-green-alt-top-header tbody tr {
	background-color: #fff !important;
}
.wp-block-table.is-style-three-col-33-33-33-green-alt-top-header tbody tr:first-child td {
	background-color: #00B9B9;
	color: #fff;
	font-size: 17px !important;
	font-weight: 700 !important;
}
.wp-block-table.is-style-three-col-33-33-33-green-alt-top-header tbody tr:first-child td:nth-child(even) {
	background-color: #707070;
}
.wp-block-table.is-style-three-col-33-33-33-green-alt-top-header tbody tr:nth-child(n+2) td {
	background-color: #D9F5F5;
	font-size: 16px !important;
}
.wp-block-table.is-style-three-col-33-33-33-green-alt-top-header tbody tr:nth-child(n+2) td:nth-child(even) {
	background-color: #ECEDEF;
}
.wp-block-table.is-style-three-col-33-33-33-green-alt-top-header tr td {
	padding-top: 1rem;
	padding-bottom: 1rem;
	border-top: 6px solid #fff !important;
	border-bottom: 6px solid #fff !important;
	text-align: center;
	font-size: 18px !important;
}
.wp-block-table.is-style-three-col-33-33-33-green-alt-top-header tr td:first-child {
	border-right: 6px solid #fff !important;
}
.wp-block-table.is-style-three-col-33-33-33-green-alt-top-header tr td:last-child {
	border-left: 6px solid #fff !important;
}
.wp-block-table.is-style-three-col-33-33-33-green-alt-top-header tr td:nth-child(1) {
	width: 33.333% !important;
}
.wp-block-table.is-style-three-col-33-33-33-green-alt-top-header tr td:nth-child(2) {
	width: 33.333% !important;
}
.wp-block-table.is-style-three-col-33-33-33-green-alt-top-header tr td:nth-child(3) {
	width: 33.333% !important;
}


/* Light Green Cells */
.wp-block-table.is-style-light-green-cells {}
.wp-block-table.is-style-light-green-cells {}
.wp-block-table.is-style-light-green-cells tr td {
	padding-top: 1rem;
	padding-bottom: 1rem;
	border-top: 6px solid #fff !important;
	border-bottom: 6px solid #fff !important;
	border-right: none !important;
	border-left: none !important;
	text-align: center;
	font-size: 18px !important;
	background-color: #D9F5F5 !important;
	color: #000 !important;
}
.wp-block-table.is-style-light-green-cells tr td:first-child {
	border-right: 6px solid #fff !important;
}
.wp-block-table.is-style-light-green-cells tr td:last-child {
	border-left: 6px solid #fff !important;
}

/* Alt Grey Rows*/
.wp-block-table.alt-grey-rows tr:nth-child(even) td {
	background-color: #F0F2F4 !important;
}

/* Text Left */
.wp-block-table.text-align-left tr td {
	text-align: left !important;
}


/* Top Header Green - Light Green Cells */
.wp-block-table.is-style-top-header-green-light-green-cells {}
.wp-block-table.is-style-top-header-green-light-green-cells tr td {
	padding-top: 1rem;
	padding-bottom: 1rem;
	border-top: 6px solid #fff !important;
	border-bottom: 6px solid #fff !important;
	text-align: center;
	font-size: 18px !important;
}
.wp-block-table.is-style-top-header-green-light-green-cells tr:first-child td {
	background-color: #00ADAC !important;
	color: #fff !important;
	border-right: none !important;
	border-left: none !important;
}
.wp-block-table.is-style-top-header-green-light-green-cells tr:nth-child(n+2) td {
	background-color: #D9F5F5 !important;
	color: #000 !important;
}
.wp-block-table.is-style-top-header-green-light-green-cells tr td:first-child {
	border-right: 6px solid #fff !important;
}
.wp-block-table.is-style-top-header-green-light-green-cells tr td:last-child {
	border-left: 6px solid #fff !important;
}


/* 
 *TOOL TIP
 */
.tooltip {
	box-shadow: 0 0 4px rgba(0,0,0,0.45);
}
.tooltip b {
	display: block;
	margin-bottom: rem-calc(5);
}
.tooltip p {
	margin-bottom: 0;
}
.tooltip button {
	position: absolute;
	top: 0;
	right: 0;
	background-image: url(../img/close-icon-black.svg);
    background-size: 12px 12px;
	background-position: center;
	background-repeat: no-repeat;
	width: 32px;
	height: 32px;
	text-indent: -5000px;
	display: block;
}
.tooltip button:hover {
	cursor: pointer;
}
.tooltip:before, .tooltip.bottom:before {
	display:none;
}

// FIX
html.is-reveal-open body { width: 100%; }
//

.hamburger-icon {
    background-image: url(../img/hamburger-icon.svg);
    background-position: center;
    background-repeat: no-repeat;
    /*background-size: cover;*/
    width: rem-calc(36);
    height: rem-calc(36);
    text-indent: -5000px;
}

.hamburger-icon:hover {
    cursor: pointer;
}


/*
 * LINK STYLES
 */
.link-icon {}
.link-icon__top {}
.link-icon__about {}
.link-icon__contact {}
.link-icon__news {}


.arrow_blocks_wrapper {
    background-color: #E6F7F7;
}

.colour-panel-blocks-3x .callout {
    height: 100%;
    display: block;
    color: #fff;
    padding-bottom: rem-calc(60);
    background-image: svgRightArrow(#fff);
    background-position: bottom 30px center;
    background-repeat: no-repeat;
    background-size: 16px;
	text-decoration: none;
}

.colour-panel-blocks-3x .callout h3, .colour-panel-blocks-3x .callout p {
    color: #fff;
    font-size: rem-calc(20);
}

.colour-panel-blocks-3x .callout h3 {
	min-height: rem-calc(56);
}

.colour-panel-blocks-3x .column:nth-child(1) .callout {
    background-color: #B05A9A;
}

.colour-panel-blocks-3x .column:nth-child(2) .callout {
    background-color: #D77B41;
}

.colour-panel-blocks-3x .column:nth-child(3) .callout {
    background-color: #6A5489;
}

.colour-panel-blocks-3x .column a:first-child:hover h3, .colour-panel-blocks-3x .column a:first-child:hover p {
    text-decoration: underline;
}


.icon-blocks h3 {
    padding-top: rem-calc(20);
    margin-bottom: rem-calc(30);
    display: inline-block;
    transition: all 0.25s;
}

.icon-blocks.has-icons h3 {
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 45px 45px;
    padding-top: rem-calc(60);
    min-width: 58px;
}

//.icon-blocks.has-icons .columns:nth-child(1) h3 {
.icon-blocks.has-icons .columns h3.binoculars {
    background-image: url( '../img/icon-binoculars.svg' );
}

.icon-blocks.has-icons .columns h3.rosette {
    background-image: url( '../img/icon-rosette.svg' );
}

.icon-blocks.has-icons .columns h3.calendar {
    background-image: url( '../img/icon-calendar.svg' );
}

.icon-blocks.has-icons .columns h3.sliders {
    background-image: url( '../img/icon-sliders.svg' );
}

.icon-blocks.has-icons .columns h3.megaphone {
    background-image: url( '../img/icon-megaphone.svg' );
}

.icon-blocks.has-icons .columns h3.speechbubble-square {
    background-image: url( '../img/icon-speechbubble-square.svg' );
}

.icon-blocks.has-icons .columns h3.chart {
    background-image: url( '../img/icon-chart.svg' );
}


.icon-blocks.has-icons .columns h3.speechbubble {
    background-image: url( '../img/icon-speechbubble.svg' );
    background-size: 58px 58px;
    padding-top: rem-calc(75);
}

.icon-blocks.has-icons .columns h3.cogs {
    background-image: url( '../img/icon-cogs.svg' );
    background-size: 65px 65px;
    padding-top: rem-calc(75);
}

.icon-blocks.has-icons .columns h3.newspaper {
    background-image: url( '../img/icon-newspaper.svg' );
    background-size: 58px 58px;
    padding-top: rem-calc(75);
}

.icon-blocks .columns a:first-child {
    display: block
}

.icon-blocks .columns a:first-child:hover h3 {
    text-decoration: underline;
}

.icon-blocks .columns a, .icon-blocks .columns a p {
    text-decoration: none !important;
}

.icon-blocks .columns a:first-child:hover div.button {
    background-color: $secondary-color;
}

.icon-blocks .columns div.button {
    background-image: svgRightArrow(#fff);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 16px;
    text-indent: -5000px;
    height: rem-calc(40);
    width: rem-calc(80);
}

.icon-blocks .columns div.button:hover {
    background-color: $secondary-color;
}


/* 0 - 739px - Small Only */
@media only screen and (max-width:46.188em) {
	.colour-panel-blocks-3x .callout {
    	min-height: 100%;
	}
	
    .icon-blocks.has-icons.icons-at-side-on-small h3 {
        background-position: top left;
        background-repeat: no-repeat;
        background-size: 30px 30px;
        padding-top: rem-calc(5);
        padding-bottom: rem-calc(5);
        padding-left: rem-calc(50);
        min-width: 58px;
    }

    .icon-blocks.top-bottom-padding-on-small a {
        padding-top: rem-calc(32);
        padding-bottom: rem-calc(32);
    }
    
    .top-borders-on-small .columns a, .icon-blocks.hide-icons-on-small.top-borders-on-small .columns a {
        border-top: 1px solid #ccc;
    }
    .hide-icons-on-small.top-borders-on-small .columns:first-child a {
        border-top: none;
    }
    
    .icon-blocks.hide-icons-on-small .columns h3 {
        background-image: none !important;
        padding-top: rem-calc(30) !important;
    }
    
}

/* 740px - 1023px - Medium Only */
@media only screen and (min-width:46.250em) and (max-width:63.938em) {
   
    
}



/*
 * NCS Single-page site
 */
.ncs_header_wrapper {
	border-bottom: 1px solid #ecedef;
	box-shadow: 0 1px 15px rgba(0,0,0,.1);
	padding-top: 14px;
	padding-bottom: 20px;
}

.ncs_header_wrapper h1 {
	text-indent: 0;
	font-size: rem-calc( 16 );
	color: $secondary-color;
	font-weight: 700;
	line-height: 1.1;
}
.ncs_content_container h2 {
    font-size: rem-calc( 44 );
    line-height: 1.2;
    font-weight: 700;
    color: $primary-color;
    margin-bottom: 0;
	
	text-shadow: 1px 1px 1px rgba(255,255,255,0.5);
}


@media only screen and (max-width: 31.188em) {
    .ncs_content_container h2 {
        font-size: 1.44444rem;
    }
}

@media only screen and (min-width: 31.25em) and(max-width: 46.188em) {
    .ncs_content_container h2 {
		font-size: 2rem;
	}
}



/*
 * General Button Styles
 */
.button {
    font-weight: 500;
}
    
// 404
#error_404 {}
#error_404 p { color: #000; }



.reveal {
    padding: rem-calc(40 40 40 40);
    box-shadow: 0 7px 30px rgba(0, 0, 0, 0.3);
    max-width: 600px;
}

.reveal.small {
    max-width: 600px;
    border-radius: 10px;
}


.nav-buttons.modal-buttons {
    margin-bottom: 0;
    transform: translateY(5px);
    height: 15px;
}

.nav-buttons a {
    margin-bottom: 0;
}


/* 0 - 739px - Small Only */
@media only screen and (max-width:46.188em) {
    .reveal.small {
        max-width: 75rem;
        border-radius: 0;
    }

}


/* 740px - 1023px - Medium Only */
@media only screen and (min-width:46.250em) and (max-width:63.938em) {
   
}
