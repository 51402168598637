/* LANDSCAPE FOOTER CSS */

.footer_wrapper {
    background: #323241;
    //margin-top: rem-calc( 30 );
}

.footer_wrapper footer {
    padding: rem-calc(25 0 35 0);
    font-size: rem-calc(14);
    color: #fff;
}

.footer_wrapper footer p {
    color: #fff;
    margin-bottom: 0;
    
}

.footer_wrapper footer .menu {
    font-size: rem-calc(14);
    justify-content: space-between;
}

.footer_wrapper footer .menu a {
    padding-left: 0;
    padding-right: 0;
    padding-top: rem-calc(5);
    padding-bottom: rem-calc(5);
}

.footer_wrapper footer .logo {
    text-align: right;
}

.footer_wrapper footer .logo img {
    max-width: 110px;
    margin-bottom: -16px;
}

.footer_wrapper footer h3 {
    color: #fff;
}

.footer_wrapper footer ul li {
    color: #fff;
}

.footer_wrapper footer a {
    color: #fff;
    border-bottom: 1px solid transparent;
    transition: border 0.25s;
}

.footer_wrapper footer a:hover {
    border-bottom: 1px solid #fff;
}

footer ul {
    font-weight: 400;
    color: #000;
    list-style: none;
    padding: 0;
    margin: 0;
    //line-height: 1.7;
}

footer ul li {
    margin-bottom: rem-calc(6);
}

footer .credits p {
    font-size: rem-calc(14);
}


/* 0 - 739px - Small Only */
@media only screen and (max-width:46.188em) {
    .footer_wrapper {
        margin-top: rem-calc( 0 );
    }
    .footer_wrapper footer .menu {
        flex-direction: column;
        margin-bottom: rem-calc(60);
    }
    
    .footer_wrapper footer .menu a {
        display: inline-block;
    }
    
    .footer_wrapper footer .logo {
        text-align: left;
        margin-bottom: rem-calc(40);
    }
    
    .footer_wrapper footer .logo img {
        margin-bottom: 0;
    }
    
    footer .credits p span {
        display: block;
    }
}

/* 740px - 1023px - Medium Only */
@media only screen and (min-width:46.250em) and (max-width:63.938em) {
}

