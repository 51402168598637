/* HEADER / NAV CSS */

h1 {
    font-size: rem-calc(1);
    text-indent: -5000px;
    margin-bottom: 0;
    border: 0;
    overflow: hidden;
    padding: 0;
}

h1 a {
    display: block;
    width: 110px;
    height: 67px;
    background-image: url( '../img/pearson-text-logo-stacked.svg' );
    background-repeat: no-repeat;
    background-size: 100%;
}

.header_wrapper {
    padding-left: 0;
	padding-right: 0;
}

.sticky {
    background-color: #fff;
    max-width: 9999px !important;
    left: 0;
    right: 0;
    padding-top: rem-calc(16);
	border-bottom: 1px solid #ECEDEF;
	box-shadow: 0 1px 15px rgba(0, 0, 0, 0.1);
}

.sticky.is-stuck {
	padding-left: rem-calc(15);
	padding-right: rem-calc(15);
	//box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

.sticky.is-anchored {
	//left: -15px;
	//right: -15px;
}

.sticky .logo_row {
	padding-left: rem-calc(15);
	padding-right: rem-calc(15);
}

/*.sticky.is-stuck .logo_row {
	padding-left: 0;
	padding-right: 0;
}*/

.sticky.is-stuck h1 a {
    //background-image: url( '../img/pearson-text-logo-line.svg' );
	background-image: url( '../img/icon-hamburger.svg');
    background-size: 31px 25px;
	background-position: 0 8px;
    width: 210px;
    height: 56px;
}

.sticky.is-stuck h1 a.close_state {
    //background-image: url( '../img/pearson-text-logo-line.svg' );
	background-image: url(../img/close-icon-black.svg);
    background-size: 31px 25px;
	background-position: 0 8px;
    width: 210px;
    height: 56px;
}

.sticky .mobile_trigger button {
	float: none;
}
.sticky .mobile_trigger button.close_state {
	background-image: url(../img/close-icon-black.svg);
	background-size: 31px 25px;
	background-position: 0 5px;
    width: 210px;
    height: 56px;
}


.sticky.is-stuck .subnav {
    display: none;
}

.mobile_trigger {
	border-top: 1px solid #BCBBBB;
	margin-top: 1.5rem;
	display: none;
	padding-left: rem-calc(15);
    padding-right: rem-calc(15);
}

.sticky.is-stuck .mobile_trigger {
    display: none;
}

/*.sticky.is-anchored .subnav {
    display: block !important;
}*/



.subnavmain {
	position: relative;
}

/* 0 - 345px */
@media only screen and (max-width:345px) { 
    .sticky.is-stuck h1 a {
        width: 190px;
        height: 51px;
    }
}
/* 0 - 1052px */
@media only screen and (max-width:1056px) { 
    .sticky.is-stuck .row:first-child {
        //padding-left: rem-calc(15);
        //padding-right: rem-calc(15);
    }
}


/* 0 - 739px - SMALL */
@media only screen and (max-width:739px) { 
    .sticky.is-stuck .row .small-5 {
        flex: 0 0 15%;
		max-width: 15%;
    }
    .sticky.is-stuck .row .small-7 {
        flex: 0 0 85%;
		max-width: 85%;
    }
	
	.subnav {
		display: none;
		z-index: 5;
	}
	
	.row.expanded .subnav {
		overflow: auto;
		background-color: #00658B;
		margin-left: -15px;
		margin-right: -15px;
		//flex: 0 0 110%;
		//max-width: (100% + 30px);*/
	}
	
	.mobile_trigger {
		display: flex;
	}
	
	.sticky.is-stuck .logo_row {
		padding-left: 0;
		padding-right: 0;
	}
}


header {
    
}

/* Microsite top-right text */
.top_text li {
	font-size: rem-calc( 16 );
	color: $secondary-color;
	font-weight: 700;
	line-height: 1.1;
	transform: translateY(6px);
}

.sticky.is-stuck .top_text li {
	transform: translateY(-6px);
}



.main_navigation {
    border-top: 1px solid #BCBBBB;
    margin-top: rem-calc(32);
    padding-top: rem-calc(8);
    padding-bottom: rem-calc(0);
	margin-left: rem-calc(15);
    margin-right: rem-calc(15);
}

header .main_nav_menu.menu {
    //justify-content: space-between;
}

/*header .main_nav_menu.menu li ul.sub-menu {*/
.header_wrapper .sub-menu-container {
    position: absolute;
	/*top: 0;*/
	left: 0;
	right: 0;
	background-color: #00658B;
	padding-top: rem-calc(40);
	padding-bottom: rem-calc(40);
	display: none;
	z-index: 999;
	min-height: 400px;
	margin-top: -1px;
}

.header_wrapper .sub-menu-container:hover {
	display: block;
}

.header_wrapper .sub-menu-parent-title {
	color: #fff;
	margin-bottom: rem-calc(45);
}

.header_wrapper .sub-menu-parent-description {
	color: #fff;
	font-size: rem-calc(23);
	padding-right: rem-calc(30);
}

.header_wrapper .sub-menu {
	margin-left: 0;
}

.header_wrapper .sub-menu li {
	margin-bottom: rem-calc(10);
}

.header_wrapper .sub-menu li a {
	color: #fff;
	text-decoration: none;
	font-size: rem-calc(21);
}

.header_wrapper .sub-menu li a:hover {
	text-decoration: underline;
}

.header_wrapper .sub-menu li {
	background-image: svgRightArrow(#00B9B9);
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 20px;
	padding-left: 28px;
	list-style: none;
}

header .main_nav_menu.menu a, header .main_nav_menu_secondary.menu a {
    color: #323241;
    font-size: rem-calc(16);
    font-weight: 400;
    position: relative;
    padding: rem-calc(10 0 10 0);
    margin-right: 20px;
    margin-left: 0;
    transition: all 0.5s;
    border-bottom: none;
    text-align: left;
    border-bottom: 4px solid transparent;
    transition: background 0.25s, font-size 0.25s;
	line-height: 1.4;
}


header .main_nav_menu.menu.microsite a, header .main_nav_menu_secondary.menu.microsite a {
    margin-right: 60px;
}



header .main_nav_menu.menu a:hover, header .main_nav_menu_secondary.menu a:hover {
    border-bottom: 4px solid $secondary-color;
}

header .main_nav_menu.menu li.current-menu-item a, header .main_nav_menu.menu li.menu-active a, header .main_nav_menu.menu li.current-menu-parent a, header .main_nav_menu_secondary.menu li.current-menu-item a, header .main_nav_menu_secondary.menu li.current-menu-parent a  {
    border-bottom: 4px solid $secondary-color;
}

header .main_nav_menu.menu li:first-child a, header .main_nav_menu_secondary.menu li:first-child a {
    margin-left: 0;
}

header .main_nav_menu.menu li:last-child a, header .main_nav_menu_secondary.menu li:last-child a {
    margin-right: 0;
    //justify-content: flex-end;
}

header .main_nav_menu.menu a:after, header .main_nav_menu_secondary.menu a:after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -10px;
    height: 16px;
    border-right: 1px solid #BCBBBB;
}

header .main_nav_menu.menu.microsite a, header .main_nav_menu_secondary.menu.microsite a {
	margin-left: 0;
}

header .main_nav_menu.menu.microsite a:after, header .main_nav_menu_secondary.menu.microsite a:after {
    content: '>';
    position: absolute;
    top: rem-calc(10);
    transform: none;
    right: rem-calc(-35);
    height: auto;
	padding-left: 0;
    border-right: none;
}

header .main_nav_menu.menu li:last-child a:after, header .main_nav_menu_secondary.menu li:last-child a:after {
    display: none;
    padding-right: 0;
}

header .menu a:hover {
    color: #000;
}

header .menu a.active, header .menu li.current-menu-item a {
}

header nav a:hover {
    /*color: #000;
    border-color: #333;*/
}

/* 0 - 413px */
@media only screen and (max-width:413px) { 
	.search_login input[type="search"]:focus, .search-form input[type="search"]:focus {
		width: 115px;
	}
}

/* 0 - 739px - Small Only */
@media only screen and (max-width:46.188em) { 
	.main_navigation {
    	border-top: none;
    	margin-top: rem-calc(0);
	}
	header .main_nav_menu.menu {
		flex-wrap: nowrap;
		flex-direction: column;
	}
	header .main_nav_menu_secondary.menu {
		flex-wrap: nowrap;
		flex-direction: column;
		justify-content: center !important;
	}
	header .main_nav_menu_secondary.menu.align-right {
		justify-content: center !important;
	}
	header .main_nav_menu_secondary.menu.align-right li {
		justify-content: center !important;
	}
	header .main_nav_menu.menu li:first-child {
		border-top: none;
	}
	header .main_nav_menu.menu li, header .main_nav_menu_secondary.menu li {
		border-top: 1px solid #fff;
		margin-left: 10px;
		margin-right: 10px;
	}
	header .main_nav_menu.menu li a, header .main_nav_menu_secondary.menu li a {
		color: #fff;
		font-size: rem-calc(18);
		font-weight: bold;
		padding: rem-calc(20 0);
		text-align: center;
	}
	header .main_nav_menu.menu li a:hover, header .main_nav_menu_secondary.menu li a:hover {
		color: #000;
	}
	header .main_nav_menu.menu li a:after, header .main_nav_menu_secondary.menu li a:after {
		display: none;
	}
	
	/*header .main_nav_menu.menu li:first-child a, header .main_nav_menu_secondary.menu li:first-child a {
		margin-left: 10px;
	}*/

	header .main_nav_menu.menu li:last-child a, header .main_nav_menu_secondary.menu li:last-child a {
		margin-right: 10px;
		//justify-content: flex-end;
	}
}

/* 0 - 800px */
@media only screen and (max-width:800px) { 
    header .main_nav_menu.menu a, header .main_nav_menu_secondary.menu a {
        font-size: rem-calc(15);
    }
}


/* 740px - 860px */
@media only screen and (min-width:740px) and (max-width:860px) { 
    header .main_nav_menu.menu li a, header .main_nav_menu_secondary.menu li a {
		//color: #fff;
		font-size: rem-calc(13);
		//font-weight: bold;
		//padding: rem-calc(20 0);
		//text-align: center;
	}
}

/* 0 - 460px */
@media only screen and (max-width:460px) { 
    header .main_nav_menu.menu li a, header .main_nav_menu_secondary.menu li a {
		//font-size: rem-calc(18);
		padding: rem-calc(12 0);
	}
}


header .menu-icon {
    text-indent: -5000px;
    float: left;
    margin-top: 0;
    margin-right: rem-calc(5);
    background: url( '../img/icon-hamburger.svg') 0 5px no-repeat;
    background-size: 31px 25px;
    width: 31px;
    height: 30px;
    
}

header .menu-icon:after {
    display: none;
}

.section_menu > li { background: #eee; margin-bottom: rem-calc(10); }
.section_menu li ul.menu.nested { margin-left: 0; }
.section_menu li ul li { background: #000; }

.main_nav_menu {
    
}


/* 0 - 739px - Small Only */
@media only screen and (max-width:46.188em) { 
    header nav {
    }
    
    .sticky.is-anchored .search_login input[type="search"] {
        display: none;
    }
}

/* 0 - 1310px */
@media only screen and (max-width:1310px) { 
    .header_wrapper {
    }
}

/* 0px and up */
@media only screen and (min-width:0em) {
    
}

/* 740px - 1023px - Medium Only */
@media only screen and (min-width:46.250em) and (max-width:63.938em) {

}


/* 740px and up */
@media only screen and (min-width:46.250em) {

}

/* 1024px and up */
@media only screen and (min-width:64em) {

}
