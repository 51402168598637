/* SASS FUNCTIONS */

// functions to urlencode the svg string
@function str-replace( $string, $search, $replace: '' ) {
  $index: str-index( $string, $search );
  @if $index {
    @return str-slice( $string, 1, $index - 1 ) + $replace + str-replace( str-slice( $string, $index + str-length( $search ) ), $search, $replace );
  }
  @return $string;
}

@function url-encode( $string ) {
  $map: (
   "%": "%25",
   "<": "%3C",
   ">": "%3E",
   " ": "%20",
   "!": "%21",
   "*": "%2A",
   "'": "%27",
   '"': "%22",
   "(": "%28",
   ")": "%29",
   ";": "%3B",
   ":": "%3A",
   "@": "%40",
   "&": "%26",
   "=": "%3D",
   "+": "%2B",
   "$": "%24",
   ",": "%2C",
   "/": "%2F",
   "?": "%3F",
   "#": "%23",
   "[": "%5B",
   "]": "%5D"
   );
  $new: $string;
  @each $search, $replace in $map {
    $new: str-replace( $new, $search, $replace );
  }
  @return $new;
}

/*
 * Social icons
 */
@function facebookIcon( $itemcolour ) {
    @return url( 'data:image/svg+xml;utf8,' + url-encode( '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.58 35.52"><path class="cls-1" d="M15.93,17.76H11V35.52H3.67V17.76H0V11.64H3.67V8c0-5,2.07-8,8-8h4.9V6.12H13.48C11.18,6.12,11,7,11,8.57v3.06h5.55Z" fill="' + $itemcolour + '" /></svg>' ) );
}

@function instagramIcon( $itemcolour ) {
    @return url( 'data:image/svg+xml;utf8,' + url-encode( '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.23 25.24"><path d="M12.62,2.27c3.37,0,3.77,0,5.1.08a6.94,6.94,0,0,1,2.34.43,3.81,3.81,0,0,1,1.45,1,3.91,3.91,0,0,1,.94,1.45,6.81,6.81,0,0,1,.44,2.34c.06,1.33.07,1.73.07,5.1s0,3.77-.07,5.1a6.87,6.87,0,0,1-.44,2.34,4.14,4.14,0,0,1-2.39,2.39,6.87,6.87,0,0,1-2.34.44C16.39,23,16,23,12.62,23s-3.77,0-5.1-.07a6.81,6.81,0,0,1-2.34-.44,3.91,3.91,0,0,1-1.45-.94,3.81,3.81,0,0,1-1-1.45,6.94,6.94,0,0,1-.43-2.34c-.06-1.33-.08-1.73-.08-5.1s0-3.77.08-5.1a6.88,6.88,0,0,1,.43-2.34,3.81,3.81,0,0,1,1-1.45,3.81,3.81,0,0,1,1.45-1,6.88,6.88,0,0,1,2.34-.43c1.33-.06,1.73-.08,5.1-.08m0-2.27C9.19,0,8.76,0,7.42.08A9.35,9.35,0,0,0,4.35.66,6.15,6.15,0,0,0,2.12,2.12,6.15,6.15,0,0,0,.66,4.35,9.35,9.35,0,0,0,.08,7.42C0,8.76,0,9.19,0,12.62s0,3.85.08,5.2a9.28,9.28,0,0,0,.58,3.06,6.19,6.19,0,0,0,1.46,2.24,6.12,6.12,0,0,0,2.23,1.45,9.32,9.32,0,0,0,3.07.59c1.34.06,1.77.08,5.2.08s3.85,0,5.2-.08a9.25,9.25,0,0,0,3.06-.59,6.37,6.37,0,0,0,3.69-3.69,9.25,9.25,0,0,0,.59-3.06c.06-1.35.07-1.78.07-5.2s0-3.86-.07-5.2a9.32,9.32,0,0,0-.59-3.07,6.12,6.12,0,0,0-1.45-2.23A6.19,6.19,0,0,0,20.88.66,9.28,9.28,0,0,0,17.82.08C16.47,0,16,0,12.62,0" fill="' + $itemcolour + '"/><path d="M12.62,6.14a6.48,6.48,0,1,0,6.48,6.48,6.48,6.48,0,0,0-6.48-6.48m0,10.68a4.21,4.21,0,1,1,4.2-4.2,4.2,4.2,0,0,1-4.2,4.2" fill="' + $itemcolour + '"/><path d="M20.87,5.88a1.52,1.52,0,1,1-1.52-1.51,1.52,1.52,0,0,1,1.52,1.51" fill="' + $itemcolour + '"/></svg>' ) );
}

@function twitterIcon( $itemcolour ) {
    @return url( 'data:image/svg+xml;utf8,' + url-encode( '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.41 27.96"><path class="cls-1" d="M30.89,7c0,.3,0,.61,0,.91,0,9.33-7.1,20.09-20.09,20.09A20,20,0,0,1,0,24.79a14.31,14.31,0,0,0,1.68.1,14.17,14.17,0,0,0,8.77-3A7.07,7.07,0,0,1,3.86,17,7.06,7.06,0,0,0,7,16.84,7.06,7.06,0,0,1,1.38,9.92V9.83a7,7,0,0,0,3.2.88A7.07,7.07,0,0,1,2.4,1.29,20,20,0,0,0,16.95,8.67,7.06,7.06,0,0,1,29,2.23,14.13,14.13,0,0,0,33.46.52a7.08,7.08,0,0,1-3.1,3.91,14.11,14.11,0,0,0,4.05-1.11A14.33,14.33,0,0,1,30.89,7Z" fill="' + $itemcolour + '" /></svg>' ) );
}

@function linkedinIcon( $itemcolour ) {
    @return url( 'data:image/svg+xml;utf8,' + url-encode( '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 75.77 76.25"><path d="M40.74 34.39v-.12l-.07.12z" fill="' + $itemcolour + '"/><path d="M70.17 0H5.6A5.54 5.54 0 0 0 0 5.46v65.33a5.54 5.54 0 0 0 5.6 5.46h64.57a5.54 5.54 0 0 0 5.6-5.46V5.46A5.54 5.54 0 0 0 70.17 0zM23 63.83H11.52V29.4H23zM17.25 24.7h-.08c-3.84 0-6.32-2.65-6.32-5.95s2.56-5.95 6.47-5.95 6.33 2.57 6.4 5.95-2.48 5.95-6.47 5.95zm47 39.13H52.79V45.41c0-4.63-1.66-7.79-5.8-7.79a6.26 6.26 0 0 0-5.87 4.19 7.85 7.85 0 0 0-.38 2.79v19.23H29.3s.15-31.2 0-34.43h11.44v4.87a11.37 11.37 0 0 1 10.32-5.68c7.53 0 13.17 4.92 13.17 15.5z" fill="' + $itemcolour + '"/></svg>' ) );
}

@function emailIcon( $itemcolour ) {
    @return url( 'data:image/svg+xml;utf8,' + url-encode( '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.73 10.52"><path fill="' + $itemcolour + '" d="M15.56 1l-5.18 4.28 5.2 4.2a1.76 1.76 0 00.15-.55V1.68a1.76 1.76 0 00-.17-.67z" /><path fill="' + $itemcolour + '" d="M1.77 0a1.77 1.77 0 00-1.2.47l6.94 5.72a.59.59 0 00.37.12.6.6 0 00.33-.1l.03-.02h.02l.3-.26L15.17.48A1.77 1.77 0 0014.12 0H1.77z" /><path fill="' + $itemcolour + '" d="M14.09 10.52a1.77 1.77 0 001.13-.51L9.86 5.7l-1.19.98a1.28 1.28 0 01-1.57 0L5.87 5.68.51 10a1.77 1.77 0 001.25.5H14.1z" /><path fill="' + $itemcolour + '" d="M.14 9.47l5.22-4.22L.18 1a1.76 1.76 0 00-.18.74v7.2a1.76 1.76 0 00.14.54z" /></svg>' ) );
}

/*
 * Tick
 */
@function tickIcon( $itemcolour ) {
    @return url( 'data:image/svg+xml;utf8,' + url-encode( '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19.72 19.04"><path fill="' + $itemcolour + '" d="M19.28 0l.44.76a38.42 38.42 0 0 0-7.96 7.68 33.36 33.36 0 0 0-5.29 9.1l-.64.42a11.44 11.44 0 0 0-1.43 1.08 10.9 10.9 0 0 0-.57-1.7l-.34-.83a21 21 0 0 0-1.86-3.79A2.37 2.37 0 0 0 0 11.56a3.35 3.35 0 0 1 2.06-1.1q1.22 0 2.67 3.28l.53 1.17a43.35 43.35 0 0 1 6.26-8.56A36.13 36.13 0 0 1 19.28 0z" /></svg>' ) );
}

/*
 * X
 */
@function xIcon( $itemcolour ) {
    @return url( 'data:image/svg+xml;utf8,' + url-encode( '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.02 13.52"><path fill="' + $itemcolour + '" d="M6.96 9.46l-.37.47q-2.74 3.6-4.26 3.6-1.14 0-2.33-1.88a3.25 3.25 0 0 0 .63.09q1.92 0 4.37-3.15l.57-.72-.41-.5Q2.2 3.92 2.2 2.53q0-1 1.6-2.53a13.98 13.98 0 0 0 2.82 5.7l.32.4.62-.74q3.02-3.74 4.8-3.74a1.7 1.7 0 0 1 1.45.87l.3.4a16.66 16.66 0 0 0-5.09 4.15l-.6.68.44.48q2.67 2.93 4.92 2.93a4.94 4.94 0 0 0 1.23-.15 7.57 7.57 0 0 1-1.22 2 1.86 1.86 0 0 1-1.34.47q-1.84 0-4.8-3.24z" /></svg>' ) );
}

/*
 * Checkmark
 */
@function checkMark( $itemcolour ) {
    @return url( 'data:image/svg+xml;utf8,' + url-encode( '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 14.4"><polygon points="18.4 0 7.2 11.2 1.6 5.6 0 7.2 7.2 14.4 8 13.6 8.8 12.8 20 1.6 18.4 0" fill="' + $itemcolour + '"/></svg>' ) );
}

/*
 * Calendar
 */
@function calendarIcon( $itemcolour ) {
    @return url( 'data:image/svg+xml;utf8,' + url-encode( '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38 38"><path fill="' + $itemcolour + '" d="M31.05 5h-5V1.93a1.26 1.26 0 1 0-2.51 0V5h-3.45V1.93a1.26 1.26 0 1 0-2.51 0V5h-3.45V1.93a1.26 1.26 0 1 0-2.51 0V5H7a4.11 4.11 0 0 0-4.16 4.12v24.1A4.11 4.11 0 0 0 7 37.33h24.1a4.11 4.11 0 0 0 4.11-4.11V9.12A4.11 4.11 0 0 0 31.05 5zm2 28.21a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V9.12a2 2 0 0 1 2-2h4.67V10a1.26 1.26 0 0 0 2.51 0V7.15h3.45V10a1.26 1.26 0 0 0 2.51 0V7.15h3.45V10a1.26 1.26 0 0 0 2.51 0V7.15h5a2 2 0 0 1 2 2z"/><path fill="' + $itemcolour + '" d="M8.57 15.53h5.94v4.7H8.57zM15.87 15.53h5.94v4.7h-5.94zM23.16 15.53h5.94v4.7h-5.94zM8.57 21.17h5.94v4.7H8.57zM15.87 21.17h5.94v4.7h-5.94zM23.16 21.17h5.94v4.7h-5.94zM8.57 26.59h5.94v4.7H8.57zM15.87 26.59h5.94v4.7h-5.94zM23.16 26.59h5.94v4.7h-5.94z"/></svg>' ) );
}

/*
 * Document
 */
@function documentIcon( $itemcolour ) {
    @return url( 'data:image/svg+xml;utf8,' + url-encode( '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.95 28.26"><path fill="' + $itemcolour + '" d="M14.46 0H0v28.26h22V7.89zm.41 2.51l4.7 5h-4.7zM1.43 26.83V1.43h12v7.46h7.08v17.94z"/><path fill="' + $itemcolour + '" d="M3.81 7.46h7.42v1.43H3.81z" /><path fill="' + $itemcolour + '" d="M3.81 20.21h14.85v1.43H3.81z" /><path fill="' + $itemcolour + '" d="M3.81 13.83h14.85v1.43H3.81z" /></svg>' ) );
}

/*
 * Download
 */
@function downloadIcon( $itemcolour ) {
    @return url( 'data:image/svg+xml;utf8,' + url-encode( '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44.97 21.75"><g fill="none" stroke="' + $itemcolour + '" stroke-miterlimit="10" stroke-width="1.5" transform="translate(-487.24 -257.43)"><path d="M487.24 264.93h12.6" data-name="Line 276"/><path d="M487.24 271.69h12.6" data-name="Line 277"/><path d="M487.24 278.44h19.9" data-name="Line 278"/><path d="M487.24 258.18h19.9" data-name="Line 279"/><path d="M531.29 268.3c-13.93 19.28-27.26 0-27.26 0s13.33-19.27 27.26 0z" /><circle stroke="' + $itemcolour + '" cx="3.79" cy="3.79" r="3.79" transform="translate(513.87 264.52)"/></g></svg>' ) );
}

/*
 * Arrow Square
 */
@function arrowSquareIcon( $itemcolour ) {
    @return url( 'data:image/svg+xml;utf8,' + url-encode( '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.77 28.24"><path fill="' + $itemcolour + '" d="M27.77 28.24H0V0h27.77zM1.56 26.68h24.65V1.56H1.56z" /><path fill="' + $itemcolour + '" d="M11.61 5.53v1.56h7.93l-9.01 8.79L11.62 17l9.06-8.84v7.93h1.56V5.53H11.61z"/></svg>' ) );
}

/*
 * Arrow helper functions
 */
@function svgRightArrow( $itemcolour ) {
    @return url( 'data:image/svg+xml;utf8,' + url-encode( '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.85 15.85"><path fill="' + $itemcolour + '" d="M9.44,14.33l-.59-.59-.59-.58,4.43-4.4H0V7.1H12.69L8.27,2.69,9.44,1.52l6.4,6.4Z"/></svg>' ) );
}

// Thin Arrow
@function svgThinArrow( $itemcolour, $direction ) {
    $directions: (
        left : 'M5.1 10.3l1-.9-3.6-3.6h7.8V4.5H2.5L6.1.9 5.2.1 0 5.2l5.1 5.1z',
        right : 'M5.2 0l-1 .9 3.6 3.6H0v1.3h7.8L4.2 9.4l.9.9 5.2-5.2L5.2 0z',
        up : 'M.1 5.1l.9 1 3.6-3.6v7.8h1.3V2.5l3.6 3.6.9-.9L5.1 0l-5 5.1z',
        down : 'M10.3 5.2l-.9-1-3.6 3.6V0H4.5v7.8L.9 4.2l-.8.9 5.2 5.2 5-5.1z'
    );
    @return url( 'data:image/svg+xml;utf8,' + url-encode( '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.4 10.4"><path fill="' + $itemcolour + '" d="' + map-get( $directions, $direction ) + '"/></svg>' ) );
}

// Triangle
@function svgTriangle( $itemcolour, $direction ) {
    $directions: (
        left : 'M36.1 71.7L0 35.6 35.6 0',
        right : 'M0 0l36.1 36.1L.5 71.7',
        up : 'M0 36.1L36.1 0l35.6 35.6',
        down : 'M71.7 0L35.6 36.1 0 .5'
    );
    //@return url( 'data:image/svg+xml;utf8,' + url-encode( '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10.4 10.4"><path fill="' + $itemcolour + '" d="' + map-get( $directions, $direction ) + '"/></svg>' ) );
    @return url( 'data:image/svg+xml;utf8,' + url-encode( '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 71.7 36.1"><path fill="' + $itemcolour + '" d="' + map-get( $directions, $direction ) + '" /></svg>' ) );
}

// Caret Arrow
@function svgCaretArrow($itemcolour,$direction) {
    $directions: (
        left : 'M12.49 1.4l-9 6.66 9 6.67',
        right : 'M3.51 14.73l9-6.66-9-6.67',
        up : 'M14.66 12.55L8 3.58l-6.66 9',
        down : 'M1.34 3.58l6.66 9 6.66-9'
    );
    //@return url('data:image/svg+xml;utf8,'+url-encode('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 56.69 56.69"><path fill="'+$itemcolour+'" d="'+map-get($directions, $direction)+'"/></svg>'));
    @return url('data:image/svg+xml;utf8,'+url-encode('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="'+$itemcolour+'" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="'+map-get($directions, $direction)+'"/></svg>'));
}

// Chevron
@function svgChevron($itemcolour,$direction) {
    $directions: (
        left : 'M11.28 14.56L4.72 7.99l6.54-6.55',
        right : 'M4.72 1.44l6.56 6.57-6.54 6.5',
        up : 'M1.44 11.28l6.57-6.56 6.55 6.54',
        down : 'M14.56 4.72l-6.57 6.56-6.55-6.54'
    );
    //@return url('data:image/svg+xml;utf8,'+url-encode('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16.17 9.4"><path fill="none" stroke="'+$itemcolour+'" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="'+map-get($directions, $direction)+'"/></svg>'));
    @return url('data:image/svg+xml;utf8,'+url-encode('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"><path fill="none" stroke="'+$itemcolour+'" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="'+map-get($directions, $direction)+'5"/></svg>'));
}
