/*
 * MARGIN / PADDING CLASSES 
 * These are intended to be used for adding or removing padding or margins
 * on elements in a way to allow flexible variations between breakpoints
 */
.unpadded .columns { padding-left:0; padding-right:0; }
.unpadded.columns { padding-left:0; padding-right:0; }
.unpadded_column, .unpadded-column { padding-left:0; padding-right:0; }
.unpadded_column_left, .unpadded-column-left { padding-left:0; }
.unpadded_column_right, .unpadded-column-right { padding-right:0; }
.small-padding-left { padding-left:15px; }
.small-padding-right { padding-right:15px; }

.no-top-padding, .top-padding-zero { padding-top:0 !important; }
.no-bottom-padding, .bottom-padding-zero { padding-bottom:0 !important; }
.no-top-margin, .top-margin-zero { margin-top:0 !important; }
.no-bottom-margin, .bottom-margin-zero { margin-bottom:0 !important; }
.no-left-padding, .left-padding-zero { padding-left:0 !important; }
.no-right-padding, .right-padding-zero { padding-right:0 !important; }
.no-left-margin, .left-margin-zero { margin-left:0 !important; }
.no-right-margin, .right-margin-zero { margin-right:0 !important; }

/* NEGATIVE MARGINS */
/* 16px Padding options */
.top-negative-margin-one { margin-top:-16px !important; }
.bottom-negative-margin-one { margin-bottom:-16px !important; }

/* 32px Padding options */
.top-negative-margin-two { margin-top:-32px !important; }
.bottom-negative-margin-two { margin-bottom:-32px !important; }

/* 48px Padding options */
.top-negative-margin-three { margin-top:-48px !important; }
.bottom-negative-margin-three { margin-bottom:-48px !important; }


/* POSTIIVE */
/* 8px Padding options */
.top-padding-half { padding-top:8px !important; }
.bottom-padding-half { padding-bottom:8px !important; }
.top-margin-half { margin-top:8px !important; }
.bottom-margin-half { margin-bottom:8px !important; }

/* 16px Padding options */
.top-padding-one { padding-top:16px !important; }
.bottom-padding-one { padding-bottom:16px !important; }
.top-margin-one { margin-top:16px !important; }
.bottom-margin-one { margin-bottom:16px !important; }

/* 32px Padding options */
.top-padding-two { padding-top:32px !important; }
.bottom-padding-two { padding-bottom:32px !important; }
.top-margin-two { margin-top:32px !important; }
.bottom-margin-two { margin-bottom:32px !important; }

/* 48px Padding options */
.top-padding-three { padding-top:48px !important; }
.bottom-padding-three { padding-bottom:48px !important; }
.top-margin-three { margin-top:48px !important; }
.bottom-margin-three { margin-bottom:48px !important; }

/* 64px Padding options */
.top-padding-four { padding-top:64px !important; }
.bottom-padding-four { padding-bottom:64px !important; }
.top-margin-four { margin-top:64px !important; }
.bottom-margin-four { margin-bottom:64px !important; }

/* 80px Padding options */
.top-padding-five { padding-top:80px !important; }
.bottom-padding-five { padding-bottom:80px !important; }
.top-margin-five { margin-top:80px !important; }
.bottom-margin-five { margin-bottom:80px !important; }

/* 96px Padding options */
.top-padding-six { padding-top:96px !important; }
.bottom-padding-six { padding-bottom:96px !important; }
.top-margin-six { margin-top:96px !important; }
.bottom-margin-six { margin-bottom:96px !important; }

/* 112px  Padding options */
.top-padding-seven { padding-top:112px !important; }
.bottom-padding-seven { padding-bottom:112px !important; }
.top-margin-seven { margin-top:112px !important; }
.bottom-margin-seven { margin-bottom:112px !important; }

/* 128px Padding options */
.top-padding-eight { padding-top:128px !important; }
.bottom-padding-eight { padding-bottom:128px !important; }
.top-margin-eight { margin-top:128px !important; }
.bottom-margin-eight { margin-bottom:128px !important; }


.alt-padding { }
.alt-padding .column:nth-child(odd) { padding-left:0; }
.alt-padding .column:nth-child(even) { padding-right:0; }

.small-inner-padding { }
.small-inner-padding .column:nth-child(odd) { padding-left:0; padding-right:20px; }
.small-inner-padding .column:nth-child(even) { padding-right:0; padding-left:20px;}

.alt-left-padding { }
.alt-left-padding .column { padding-left:0; }

.padding-right-restore, .padding-right-restore.columns { padding-right:30px; }
.padding-left-restore, .padding-left-restore.columns { padding-left:30px; }

/* 1280px - large */
@media only screen and (max-width:80em) {
    .unpadded-column-large { padding-left:0; padding-right:0; }

}
/* 1023px - med */
@media only screen and (max-width:63.938em) {
    .unpadded-column-medium { padding-left:0; padding-right:0; }

}


/* 0 - 739px - Small Only */
@media only screen and (max-width:46.188em) {        
    .padding-right-restore, .padding-right-restore.columns { padding-right:15px; }
    .padding-left-restore, .padding-left-restore.columns { padding-left:15px; }

    .no-left-padding-small-only { padding-left:0 !important; }
    .no-right-padding-small-only { padding-right:0 !important; }
}
/* 740px - 1023px - Medium Only */
@media only screen and (min-width:46.250em) and (max-width:63.938em) {    
    .no-left-padding-medium-only { padding-left:0 !important; }
    .no-right-padding-medium-only { padding-right:0 !important; }
}
/* 1023px - 1199px - Large Only */
@media only screen and (min-width:64em) and (max-width:74.938em) {    
    .no-left-padding-large-only { padding-left:0 !important; }
    .no-right-padding-large-only { padding-right:0 !important; }
}


/* Min-width sized classes */
/* 0px and up */
@media only screen and (min-width:0em) and (max-width:46.188em) {
    /* NEGATIVE MARGINS */
    /* 16px Padding options */
    .top-negative-margin-one-small { margin-top:-16px !important; }
    .bottom-negative-margin-one-small { margin-bottom:-16px !important; }

    /* 32px Padding options */
    .top-negative-margin-two-small { margin-top:-32px !important; }
    .bottom-negative-margin-two-small { margin-bottom:-32px !important; }

    /* 48px Padding options */
    .top-negative-margin-three-small { margin-top:-48px !important; }
    .bottom-negative-margin-three-small { margin-bottom:-48px !important; }
    
    
    /* 0 Left / Right Padding options */
    .no-left-padding-small { padding-left:0 !important; }
    .no-right-padding-small { padding-right:0 !important; }
    /* 0 Top / Bottom Padding options */
    .top-padding-zero-small { padding-top:0 !important; }
    .bottom-padding-zero-small { padding-bottom:0 !important; }
    .top-margin-zero-small { margin-top:0 !important; }
    .bottom-margin-zero-small { margin-bottom:0 !important; }
    /* 8px Top / Bottom Padding options */
    .top-padding-half-small { padding-top:8px !important; }
    .bottom-padding-half-small { padding-bottom:8px !important; }
    .top-margin-half-small { margin-top:8px !important; }
    .bottom-margin-half-small { margin-bottom:8px !important; }
    /* 16px Top / Bottom Padding options */
    .top-padding-one-small { padding-top:16px !important; }
    .bottom-padding-one-small { padding-bottom:16px !important; }
    .top-margin-one-small { margin-top:16px !important; }
    .bottom-margin-one-small { margin-bottom:16px !important; }
    /* 32px Top / Bottom Padding options */
    .top-padding-two-small { padding-top:32px !important; }
    .bottom-padding-two-small { padding-bottom:32px !important; }
    .top-margin-two-small { margin-top:32px !important; }
    .bottom-margin-two-small { margin-bottom:32px !important; }
    /* 48px Top / Bottom Padding options */
    .top-padding-three-small { padding-top:48px !important; }
    .bottom-padding-three-small { padding-bottom:48px !important; }
    .top-margin-three-small { margin-top:48px !important; }
    .bottom-margin-three-small { margin-bottom:48px !important; }
    /* 64px Top / Bottom Padding options */
    .top-padding-four-small { padding-top:64px !important; }
    .bottom-padding-four-small { padding-bottom:64px !important; }
    .top-margin-four-small { margin-top:64px !important; }
    .bottom-margin-four-small { margin-bottom:64px !important; }
    /* 80px Padding options */
    .top-padding-five-small { padding-top:80px !important; }
    .bottom-padding-five-small { padding-bottom:80px !important; }
    .top-margin-five-small { margin-top:80px !important; }
    .bottom-margin-five-small { margin-bottom:80px !important; }
    /* 96px Padding options */
    .top-padding-six-small { padding-top:96px !important; }
    .bottom-padding-six-small { padding-bottom:96px !important; }
    .top-margin-six-small { margin-top:96px !important; }
    .bottom-margin-six-small { margin-bottom:96px !important; }
    /* 112px  Padding options */
    .top-padding-seven-small { padding-top:112px !important; }
    .bottom-padding-seven-small { padding-bottom:112px !important; }
    .top-margin-seven-small { margin-top:112px !important; }
    .bottom-margin-seven-small { margin-bottom:112px !important; }
    /* 128px Top / Bottom Padding options */
    .top-padding-eight-small { padding-top:128px !important; }
    .bottom-padding-eight-small { padding-bottom:128px !important; }
    .top-margin-eight-small { margin-top:128px !important; }
    .bottom-margin-eight-small { margin-bottom:128px !important; }
}
/* 740px and up */
@media only screen and (min-width:46.250em) {
    /* NEGATIVE MARGINS */
    /* 16px Padding options */
    .top-negative-margin-one-medium { margin-top:-16px !important; }
    .bottom-negative-margin-one-medium { margin-bottom:-16px !important; }

    /* 32px Padding options */
    .top-negative-margin-two-medium { margin-top:-32px !important; }
    .bottom-negative-margin-two-medium { margin-bottom:-32px !important; }

    /* 48px Padding options */
    .top-negative-margin-three-medium { margin-top:-48px !important; }
    .bottom-negative-margin-three-medium { margin-bottom:-48px !important; }
    
    
    /* 0 Left / Right Padding options */
    .no-left-padding-medium { padding-left:0 !important; }
    .no-right-padding-medium { padding-right:0 !important; }
    /* 0 Top / Bottom Padding options */
    .top-padding-zero-medium { padding-top:0 !important; }
    .bottom-padding-zero-medium { padding-bottom:0 !important; }
    .top-margin-zero-medium { margin-top:0 !important; }
    .bottom-margin-zero-medium { margin-bottom:0 !important; }
    /* 8px Top / Bottom Padding options */
    .top-padding-half-medium { padding-top:8px !important; }
    .bottom-padding-half-medium { padding-bottom:8px !important; }
    .top-margin-half-medium { margin-top:8px !important; }
    .bottom-margin-half-medium { margin-bottom:8px !important; }
    /* 16px Top / Bottom Padding options */
    .top-padding-one-medium { padding-top:16px !important; }
    .bottom-padding-one-medium { padding-bottom:16px !important; }
    .top-margin-one-medium { margin-top:16px !important; }
    .bottom-margin-one-medium { margin-bottom:16px !important; }
    /* 32px Top / Bottom Padding options */
    .top-padding-two-medium { padding-top:32px !important; }
    .bottom-padding-two-medium { padding-bottom:32px !important; }
    .top-margin-two-medium { margin-top:32px !important; }
    .bottom-margin-two-medium { margin-bottom:32px !important; }
    /* 48px Top / Bottom Padding options */
    .top-padding-three-medium { padding-top:48px !important; }
    .bottom-padding-three-medium { padding-bottom:48px !important; }
    .top-margin-three-medium { margin-top:48px !important; }
    .bottom-margin-three-medium { margin-bottom:48px !important; }
    /* 64px Top / Bottom Padding options */
    .top-padding-four-medium { padding-top:64px !important; }
    .bottom-padding-four-medium { padding-bottom:64px !important; }
    .top-margin-four-medium { margin-top:64px !important; }
    .bottom-margin-four-medium { margin-bottom:64px !important; }
    /* 80px Padding options */
    .top-padding-five-medium { padding-top:80px !important; }
    .bottom-padding-five-medium { padding-bottom:80px !important; }
    .top-margin-five-medium { margin-top:80px !important; }
    .bottom-margin-five-medium { margin-bottom:80px !important; }
    /* 96px Padding options */
    .top-padding-six-medium { padding-top:96px !important; }
    .bottom-padding-six-medium { padding-bottom:96px !important; }
    .top-margin-six-medium { margin-top:96px !important; }
    .bottom-margin-six-medium { margin-bottom:96px !important; }
    /* 112px  Padding options */
    .top-padding-seven-medium { padding-top:112px !important; }
    .bottom-padding-seven-medium { padding-bottom:112px !important; }
    .top-margin-seven-medium { margin-top:112px !important; }
    .bottom-margin-seven-medium { margin-bottom:112px !important; }
    /* 128px Top / Bottom Padding options */
    .top-padding-eight-medium { padding-top:128px !important; }
    .bottom-padding-eight-medium { padding-bottom:128px !important; }
    .top-margin-eight-medium { margin-top:128px !important; }
    .bottom-margin-eight-medium { margin-bottom:128px !important; }
}
/* 1024px and up */
@media only screen and (min-width:64em) {
    /* NEGATIVE MARGINS */
    /* 16px Padding options */
    .top-negative-margin-one-large { margin-top:-16px !important; }
    .bottom-negative-margin-one-large { margin-bottom:-16px !important; }

    /* 32px Padding options */
    .top-negative-margin-two-large { margin-top:-32px !important; }
    .bottom-negative-margin-two-large { margin-bottom:-32px !important; }

    /* 48px Padding options */
    .top-negative-margin-three-large { margin-top:-48px !important; }
    .bottom-negative-margin-three-large { margin-bottom:-48px !important; }
    
    
    /* 0 Left / Right Padding options */
    .no-left-padding-large { padding-left:0 !important; }
    .no-right-padding-large { padding-right:0 !important; }
    /* 0 Top / Bottom Padding options */
    .top-padding-zero-large { padding-top:0 !important; }
    .bottom-padding-zero-large { padding-bottom:0 !important; }
    .top-margin-zero-large { margin-top:0 !important; }
    .bottom-margin-zero-large { margin-bottom:0 !important; }
    /* 8px Top / Bottom Padding options */
    .top-padding-half-large { padding-top:8px !important; }
    .bottom-padding-half-large { padding-bottom:8px !important; }
    .top-margin-half-large { margin-top:8px !important; }
    .bottom-margin-half-large { margin-bottom:8px !important; }
    /* 16px Top / Bottom Padding options */
    .top-padding-one-large { padding-top:16px !important; }
    .bottom-padding-one-large { padding-bottom:16px !important; }
    .top-margin-one-large { margin-top:16px !important; }
    .bottom-margin-one-large { margin-bottom:16px !important; }
    /* 32px Top / Bottom Padding options */
    .top-padding-two-large { padding-top:32px !important; }
    .bottom-padding-two-large { padding-bottom:32px !important; }
    .top-margin-two-large { margin-top:32px !important; }
    .bottom-margin-two-large { margin-bottom:32px !important; }
    /* 48px Top / Bottom Padding options */
    .top-padding-three-large { padding-top:48px !important; }
    .bottom-padding-three-large { padding-bottom:48px !important; }
    .top-margin-three-large { margin-top:48px !important; }
    .bottom-margin-three-large { margin-bottom:48px !important; }
    /* 64px Top / Bottom Padding options */
    .top-padding-four-large { padding-top:64px !important; }
    .bottom-padding-four-large { padding-bottom:64px !important; }
    .top-margin-four-large { margin-top:64px !important; }
    .bottom-margin-four-large { margin-bottom:64px !important; }
    /* 80px Padding options */
    .top-padding-five-large { padding-top:80px !important; }
    .bottom-padding-five-large { padding-bottom:80px !important; }
    .top-margin-five-large { margin-top:80px !important; }
    .bottom-margin-five-large { margin-bottom:80px !important; }
    /* 96px Padding options */
    .top-padding-six-large { padding-top:96px !important; }
    .bottom-padding-six-large { padding-bottom:96px !important; }
    .top-margin-six-large { margin-top:96px !important; }
    .bottom-margin-six-large { margin-bottom:96px !important; }
    /* 112px  Padding options */
    .top-padding-seven-large { padding-top:112px !important; }
    .bottom-padding-seven-large { padding-bottom:112px !important; }
    .top-margin-seven-large { margin-top:112px !important; }
    .bottom-margin-seven-large { margin-bottom:112px !important; }
    /* 128px Top / Bottom Padding options */
    .top-padding-eight-large { padding-top:128px !important; }
    .bottom-padding-eight-large { padding-bottom:128px !important; }
    .top-margin-eight-large { margin-top:128px !important; }
    .bottom-margin-eight-large { margin-bottom:128px !important; }
}